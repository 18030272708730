const withNextAction = (url, returnUrl) => {
  const urlObject = new URL(url, window.location.href);

  if (returnUrl) {
    urlObject.searchParams.append('next_action', returnUrl);
  } else {
    const urlToReturnTo = new URL(window.location.href);
    const returnToUrlAsAction = [urlToReturnTo.pathname, urlToReturnTo.search, urlToReturnTo.hash].join('');
    urlObject.searchParams.append('next_action', returnToUrlAsAction);
  }

  return urlObject.href;
};

export default withNextAction;
