import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import Text from '../../Text';
import Box from '../../Box';
import Image from '../../Image';
import Skeleton from '../../Skeleton';
import Sheets, { Sheet } from '../../Sheets';
import * as constants from '../../constants';
import List, { ListItem, ListMedia, ListContent } from '../../List';
import { sortItems, itemsPropShape } from '../utils';
import MobileSubMenu from './MobileSubMenu';
import Header from './Header';
import UserNav from './UserNav';
import Footer from './Footer';
import styles from './mobileStyles.scss';
import ItemLink from '../ItemLink';
import NavBadge from '../NavBadge';

const messages = defineMessages({
  navMenuTitle: 'Products',
  productCategoryTitle: 'Product Categories',
  userMenuTitle: 'User Menu',
});

const itemHasChildren = rootItem => !!(Array.isArray(rootItem.items) && rootItem.items.length);

class MobileProductNavListItem extends Component {
  constructor(props) {
    super(props);

    this.goToSubmenuIfHasChildren = this.goToSubmenuIfHasChildren.bind(this);
  }

  goToSubmenuIfHasChildren(ev) {
    const { rootItem, goToSubMenu } = this.props;
    if (itemHasChildren(rootItem)) {
      ev.preventDefault();
      goToSubMenu(rootItem);
    }
  }

  render() {
    const { rootItem, logEvent } = this.props;

    return (
      <ListItem
        key={`global-nav_${rootItem.id}`}
        arrow
        onClick={this.goToSubmenuIfHasChildren}
        element={ItemLink}
        action={rootItem.action}
        logEvent={logEvent}
      >
        <ListMedia>
          <Box
            style={{
              width: 'var(--ds-size-dimension-xxl)',
            }}
            marginLeft="m"
          >
            {
              rootItem.icon.url ? (
                <Image
                  fluid
                  src={rootItem.icon.url}
                  roundedCorners
                  ratio={constants.RATIO_SQUARE}
                />
              ) : (
                <Skeleton ratio={constants.RATIO_SQUARE} />
              )
            }
          </Box>
        </ListMedia>
        <ListContent>
          <Text display="block">
            {rootItem.label}
            <NavBadge badge={rootItem.badge} />
          </Text>
        </ListContent>
      </ListItem>
    );
  }
}

class MobileProductNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: {},
    };
    this.goToSubMenu = this.goToSubMenu.bind(this);
  }

  goToSubMenu(openSheet, rootItem) {
    if (rootItem) {
      this.setState(
        { activeCategory: rootItem },
        () => openSheet('navSubMenu'),
      );
    }
  }

  render() {
    const {
      intl,
      userInfo,
      userAccount,
      doSignup,
      doLogin,
      baseUrl,
      items: rootItems,
      logEvent,
      onNavItemClick,
      onNavItemView,
      redirectUrl,
    } = this.props;

    const { isArtist, onboardedAt } = userAccount || {};

    return (
      <Box
        style={{
          minWidth: '300px',
          height: '100%',
        }}
      >
        <Sheets
          title={intl.formatMessage(messages.navMenuTitle)}
          hideTitleBar
          sheets={{
            navSubMenu: (
              <Sheet title={intl.formatMessage(messages.productCategoryTitle)}>
                {({ goBack }) => (
                  <MobileSubMenu
                    intl={intl}
                    rootItem={this.state.activeCategory}
                    locale={userInfo.locale}
                    goBack={goBack}
                    logEvent={logEvent}
                    onNavItemClick={onNavItemClick}
                  />
                )}
              </Sheet>
            ),
            userAccountMenu: (
              <Sheet title={intl.formatMessage(messages.userMenuTitle)}>
                {({ goBack }) => (
                  <UserNav
                    {...this.props.userInfo}
                    intl={this.props.intl}
                    isArtist={isArtist}
                    locale={this.props.userInfo.locale}
                    closeUserNav={goBack}
                    visible
                    onboardedAt={onboardedAt}
                    goBack={goBack}
                  />
                )}
              </Sheet>
            ),
          }}
        >
          {({ openSheet }) => (
            <Box className={styles.navContainer}>
              <Header
                intl={intl}
                userInfo={userInfo}
                userAccount={userAccount}
                openUserAccountMenu={() => openSheet('userAccountMenu')}
                doLogin={doLogin}
                doSignup={doSignup}
                baseUrl={baseUrl}
                redirectUrl={redirectUrl}
              />

              {
                Array.isArray(rootItems) && !!rootItems.length && (
                  <List
                    divided
                  >
                    {
                      [...rootItems].sort(sortItems).map(rootItem => (
                        <MobileProductNavListItem
                          rootItem={rootItem}
                          goToSubMenu={(item) => {
                            if (onNavItemView) onNavItemView(item);
                            this.goToSubMenu(openSheet, item);
                          }}
                          logEvent={logEvent}
                        />
                      ))
                    }
                  </List>
                )
              }

              <Box paddingTop="m" flex="1" style={{ borderTop: 'var(--ds-size-border-width-hairline) solid var(--ds-color-border)' }}>
                <Footer
                  locale={userInfo.locale || 'en'}
                />
              </Box>
            </Box>
          )}
        </Sheets>
      </Box>
    );
  }
}


MobileProductNav.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  userInfo: PropTypes.shape({
    avatar: PropTypes.string,
    username: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  }),
  userAccount: PropTypes.shape({
    isArtist: PropTypes.bool,
  }),
  baseUrl: PropTypes.string.isRequired,
  doLogin: PropTypes.func,
  doSignup: PropTypes.func,
  // eslint-disable-next-line react/no-typos
  items: itemsPropShape,
  logEvent: PropTypes.func,
  onNavItemView: PropTypes.func,
  onNavItemClick: PropTypes.func,
  redirectUrl: PropTypes.string,
};

MobileProductNav.defaultProps = {
  userInfo: {},
  userAccount: null,
  doLogin: null,
  doSignup: null,
  items: [],
  logEvent: () => {},
};

export default MobileProductNav;
