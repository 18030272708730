import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List, { ListItem } from '../../../../List';
import Text from '../../../../Text';
import Box from '../../../../Box';
import _styles from './themes/default.scss';
import withNextAction from '../../../../utils/withNextAction';

import SocialInstagramIcon from '../../../../Icons/SocialInstagram';
import SocialFacebookIcon from '../../../../Icons/SocialFacebook';
import SocialTwitterIcon from '../../../../Icons/SocialTwitter';
import SocialTumblrIcon from '../../../../Icons/SocialTumblr';
import SocialPinterestIcon from '../../../../Icons/SocialPinterest';

// eslint-disable-next-line no-undef
const openCookiebotSettings = () => Object.hasOwnProperty.call(window, 'Cookiebot') && Cookiebot.renew();

const FooterNav = ({
  userInfo,
  baseUrl,
  studentDiscountComponent,
  country,
  locale,
  styles,
  parentStyles,
  redirectUrl,
}) => (
  <div className={cnames(
    styles.footerNavigation,
    parentStyles.footerNavigation,
  )}
  >
    <div className={styles.footerContainer}>
      <List>
        <ListItem className={styles.listHeading}>
          <Text element="h6" type="display5" display="block">
            <FormattedMessage defaultMessage="Shop" />
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/g/gifts`}>
              <FormattedMessage defaultMessage="Gift Guides" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/g/fan-art`}>
              <FormattedMessage defaultMessage="Fan Art" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/shop/new-works`}>
              <FormattedMessage defaultMessage="New Works" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="/life/">
              <FormattedMessage defaultMessage="Blog" />
            </a>
          </Text>
        </ListItem>

        { studentDiscountComponent(country) }

        {
          !userInfo || !userInfo.isLoggedIn ? ([
            <ListItem key="login">
              <Text type="body">
                <a
                  className={styles.link}
                  onClick={(ev) => {
                    // Generate link in onClick to prevent server-side rendering issues
                    window.location.href = withNextAction(`${baseUrl}/auth/login`, redirectUrl);
                    ev.preventDefault();
                  }}
                  href={`${baseUrl}/auth/login`}
                  rel="nofollow"
                >
                  <FormattedMessage defaultMessage="Login" />
                </a>
              </Text>
            </ListItem>,
            <ListItem key="signup">
              <Text type="body">
                <a
                  className={styles.link}
                  onClick={(ev) => {
                    // Generate link in onClick to prevent server-side rendering issues
                    window.location.href = withNextAction(`${baseUrl}/signup`, redirectUrl);
                    ev.preventDefault();
                  }}
                  href={`${baseUrl}/signup`}
                  rel="nofollow"
                >
                  <FormattedMessage defaultMessage="Signup" />
                </a>
              </Text>
            </ListItem>,
          ]) : ([
            <ListItem key="logout">
              <Text type="body">
                <a className={styles.link} href={`${baseUrl}/auth/logout`} rel="nofollow">
                  <FormattedMessage defaultMessage="Logout" />
                </a>
              </Text>
            </ListItem>,
          ])
        }
        {
          locale === 'en' && (
            <ListItem>
              <Text>
                <a
                  className={styles.link}
                  href="https://help.redbubble.com/hc/en-us/articles/360024412152"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Bulk orders
                </a>
              </Text>
            </ListItem>
          )
        }
      </List>
      <List>
        <ListItem className={styles.listHeading}>
          <Text element="h6" type="display5" display="block">
            <FormattedMessage defaultMessage="About" />
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/about`}>
              <FormattedMessage defaultMessage="About Us" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`/social-responsibility/${locale === 'en' ? '' : locale}`}>
              <FormattedMessage defaultMessage="Social Responsibility" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://shareholders.redbubble.com/" target="_blank" rel="noopener noreferrer">
              <FormattedMessage defaultMessage="Investor Center" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/360001021543`}>
              <FormattedMessage defaultMessage="Partner Program" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/p/398-affiliate-program`} rel="nofollow">
              <FormattedMessage defaultMessage="Affiliates" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/about/selling`}>
              <FormattedMessage defaultMessage="Sell your art" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://careers.redbubble.com">
              <FormattedMessage defaultMessage="Jobs" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://blog.redbubble.com/${locale}`}>
              <FormattedMessage defaultMessage="Artist Blog" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://www.dashery.com">
              <FormattedMessage defaultMessage="Create a Dashery store" />
            </a>
          </Text>
        </ListItem>
      </List>
      <List>
        <ListItem className={styles.listHeading}>
          <Text element="h6" type="display5" display="block">
            <FormattedMessage defaultMessage="Help" />
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/203024315`} rel="nofollow">
              <FormattedMessage defaultMessage="Delivery" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/5857691382164`} rel="nofollow">
              <FormattedMessage defaultMessage="Returns" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/`} rel="nofollow">
              <FormattedMessage defaultMessage="Help Center" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/202270929`} rel="nofollow">
              <FormattedMessage defaultMessage="Guidelines" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/34188301102996`} rel="nofollow">
              <FormattedMessage defaultMessage="Product Safety" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`https://help.redbubble.com/hc/${locale}/articles/201579195`} rel="nofollow">
              <FormattedMessage defaultMessage="Copyright" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href={`${baseUrl}/contact`}>
              <FormattedMessage defaultMessage="Contact Us" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <button type="button" className={styles.button} onClick={openCookiebotSettings}>
              <FormattedMessage defaultMessage="Cookie Settings" />
            </button>
          </Text>
        </ListItem>
      </List>
      <List>
        <ListItem className={styles.listHeading}>
          <Text element="h6" type="display5" display="block">
            <FormattedMessage defaultMessage="Social" />
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://www.instagram.com/redbubble">
              <SocialInstagramIcon />
              <Box display="inline-block" marginRight="s" />
              <FormattedMessage defaultMessage="Instagram" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://www.facebook.com/Redbubble">
              <SocialFacebookIcon />
              <Box display="inline-block" marginRight="s" />
              <FormattedMessage defaultMessage="Facebook" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://twitter.com/redbubble">
              <SocialTwitterIcon />
              <Box display="inline-block" marginRight="s" />
              <FormattedMessage defaultMessage="Twitter" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://redbubble.tumblr.com">
              <SocialTumblrIcon />
              <Box display="inline-block" marginRight="s" />
              <FormattedMessage defaultMessage="Tumblr" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body">
            <a className={styles.link} href="https://www.pinterest.com/redbubble">
              <SocialPinterestIcon />
              <Box display="inline-block" marginRight="s" />
              <FormattedMessage defaultMessage="Pinterest" />
            </a>
          </Text>
        </ListItem>
      </List>
    </div>
  </div>
);

FooterNav.propTypes = {
  userInfo: PropTypes.shape({
    locale: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
  }),
  studentDiscountComponent: PropTypes.func,
  country: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}).isRequired,
  redirectUrl: PropTypes.string,
};

FooterNav.defaultProps = {
  userInfo: {},
  studentDiscountComponent: () => {},
  styles: _styles,
  redirectUrl: '',
};

FooterNav.displayName = 'FooterNav';

export default FooterNav;
