import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import * as constants from '../constants';
import _styles from './styles.scss';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';

export const events = { EVENT_CLICK };

const LoadingState = ({ styles }) => (
  <span className={styles.loadingState}>
    <span className={styles.bounce} />
    <span className={styles.bounce} />
  </span>
);

LoadingState.propTypes = {
  styles: PropTypes.shape({}).isRequired,
};

const {
  PRIMARY,
  EXPRESSIVE,
  DESTRUCTIVE,
  TOGGLE,
  NEUTRAL,
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
} = constants;

const getClassNames = (props) => {
  const {
    fluid,
    intent,
    elevation,
    size,
    circle,
    disabled,
    strong,
    border,
    active,
    inverse,
    loading,
    styles,
    fontSize,
  } = props;

  return cnames(styles.button, {
    [styles.primary]: intent === PRIMARY,
    [styles.expressive]: intent === EXPRESSIVE,
    [styles.destructive]: intent === DESTRUCTIVE,
    [styles.neutral]: intent === NEUTRAL,
    [styles.toggle]: intent === TOGGLE,
    [styles.elevation]: !!elevation,
    [styles.elevationLow]: elevation === constants.ELEVATION_LOW,
    [styles.elevationMedium]: elevation === constants.ELEVATION_MEDIUM,
    [styles.elevationHigh]: elevation === constants.ELEVATION_HIGH,
    [styles.circle]: circle,
    [styles.fluid]: fluid && !circle,
    [styles.loading]: loading,
    [styles.strong]: strong,
    [styles.border]: !strong && border,
    [styles.active]: active,
    [styles.inverse]: inverse,
    [styles.small]: size === SMALL,
    [styles.medium]: size === MEDIUM,
    [styles.large]: size === LARGE,
    [styles.xlarge]: size === XLARGE,
    [styles.disabled]: disabled,
    [styles[fontSize]]: !!fontSize,
  });
};

const preventDefault = (props) => {
  const { href, disabled } = props;

  return href === '' || disabled;
};

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    theme,
    intent,
    size,
    loading,
    iconBefore,
    iconAfter,
    target,
    href,
    type,
    className,
    styles,
    disabled,
    strong,
    border,
    fluid,
    inverse,
    elevation,
    active,
    circle,
    onClick,
    innerRef,
    tracking,
    ...rest
  } = props;

  const { track } = useTracking(tracking, 'Button');

  const handleClick = (e) => {
    track(EVENT_CLICK);

    if (preventDefault(props)) {
      e.preventDefault();
    }

    if (disabled) {
      return;
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  const relAttribute = target === '_blank' ? 'noopener noreferrer' : undefined;

  const buttonChildren = (
    <React.Fragment>
      {loading && <LoadingState styles={styles} />}
      {(iconBefore || iconAfter) && (
        <React.Fragment>
          {iconBefore && (
            <span className={styles.iconBefore}>{iconBefore}</span>
          )}
          {children && <span className={styles.children}>{children}</span>}
          {iconAfter && <span className={styles.iconAfter}>{iconAfter}</span>}
        </React.Fragment>
      )}
      {!iconBefore && !iconAfter && (
        <span className={styles.children}>{children}</span>
      )}
    </React.Fragment>
  );

  // eslint-disable-next-line react/button-has-type
  return href ? (
    <a
      disabled={!!disabled}
      onClick={handleClick}
      target={target}
      href={href}
      rel={relAttribute}
      ref={ref || innerRef}
      {...rest}
      className={getClassNames(props)}
    >
      {buttonChildren}
    </a>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={handleClick}
      disabled={!!disabled}
      type={type}
      ref={ref || innerRef}
      {...rest}
      className={getClassNames(props)}
    >
      {buttonChildren}
    </button>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  /**
   * The text to be shown in the button
   */
  children: PropTypes.node,
  /**
   * Fired when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * If true, the button width will fill it's container
   */
  fluid: PropTypes.bool,
  /**
   * If true, the button will be suitable to use on a dark background
   */
  inverse: PropTypes.bool,
  /**
   * Changes the appearance of the button based on it's semantic purpose.
   */
  intent: PropTypes.oneOf([DESTRUCTIVE, PRIMARY, EXPRESSIVE, TOGGLE, NEUTRAL]),
  /**
   * How high (shadow) is the button floating above other content on the page
   */
  elevation: PropTypes.oneOf([
    constants.ELEVATION_LOW,
    constants.ELEVATION_MEDIUM,
    constants.ELEVATION_HIGH,
    null,
  ]),
  /**
   * Renders the button and it's text in different sizes
   */
  size: PropTypes.oneOf([SMALL, MEDIUM, LARGE, XLARGE]),
  /**
   * Makes the button more visually prominent
   */
  strong: PropTypes.bool,
  /**
   * Makes the button have a border
   */
  border: PropTypes.bool,
  /**
   * When true, forces the button to be a circle
   */
  circle: PropTypes.bool,
  /**
   * When true, disables interactions with the button and visually grays it out
   */
  disabled: PropTypes.bool,
  /**
   * HTML button tag type attribute
   */
  type: PropTypes.string,
  /**
   * An HTML href attribute. When provided, renders an anchor tag instead of a button tag
   */
  href: PropTypes.string,
  /**
   * The HTML anchor tag target attribute. Only applied if href is provided
   */
  target: PropTypes.string,
  /**
   * When true, the button will have an animation to it
   */
  loading: PropTypes.bool,
  /**
   * An <Icon /> to show on the right of the button
   */
  iconAfter: PropTypes.node,
  /**
   * An <Icon /> to show on the left of the button
   */
  iconBefore: PropTypes.node,
  /**
   * The CSS classNames for this component. NEVER manually specify this prop
   */
  styles: PropTypes.shape({}),
  /**
   * Internal use only, to support ButtonToggle
   */
  active: PropTypes.bool,
  /**
   * Tracking configuration for auto tracking
   */
  tracking: trackingShape,
  /**
   * Font size for experiment SE2504
   */
  fontSize: PropTypes.string,
};

Button.defaultProps = {
  children: '',
  onClick: null,
  fluid: false,
  href: null,
  target: '_self',
  type: 'button',
  circle: false,
  iconBefore: null,
  iconAfter: null,
  inverse: false,
  strong: false,
  border: false,
  intent: NEUTRAL,
  elevation: null,
  size: MEDIUM,
  disabled: false,
  loading: false,
  active: false,
  tracking: null,
  styles: _styles,
};

export default Button;
