import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Text from '../../Text';
import TextLink from '../../TextLink';
import Box from '../../Box';
import AuthenticatedHeader from './AuthenticatedHeader';
import withNextAction from '../../utils/withNextAction';

const messages = defineMessages({
  loggedInMenuWelcomeMessage: 'Hi there!',
});

const Header = ({
  intl,
  userInfo,
  baseUrl,
  doLogin,
  doSignup,
  openUserAccountMenu,
  redirectUrl,
}) => {
  if (userInfo.isLoggedIn) {
    return (
      <AuthenticatedHeader
        userInfo={userInfo}
        intl={intl}
        openUserAccountMenu={openUserAccountMenu}
      />
    );
  }

  const logInLink = (
    <FormattedMessage
      defaultMessage="Log In"
      id="logInOrSignUp.logIn"
      description="The 'Log In' text within a link to the Log In page which forms part of the 'Log In or Sign Up' string"
    >
      {txt => (
        <TextLink
          onClick={(ev) => {
            if (doLogin) {
              return doLogin(ev);
            }

            // Generate link in onClick to prevent server-side rendering issues
            window.location.href = withNextAction(`${baseUrl}/auth/login`, redirectUrl);
            return ev.preventDefault();
          }}
          href={`${baseUrl}/auth/login`}
          data-testid="ds-header-login-action"
        >
          { txt }
        </TextLink>
      )}
    </FormattedMessage>
  );

  const signUpLink = (
    <FormattedMessage
      defaultMessage="Sign Up"
      id="logInOrSignUp.signUp"
      description="The 'Sign Up' text within a link to the Sign Up page which forms part of the 'Log In or Sign Up' string"
    >
      {txt => (
        <TextLink
          onClick={(ev) => {
            if (doSignup) {
              return doSignup(ev);
            }

            // Generate link in onClick to prevent server-side rendering issues
            window.location.href = withNextAction(`${baseUrl}/signup`, redirectUrl);
            return ev.preventDefault();
          }}
          href={`${baseUrl}/signup`}
          data-testid="ds-header-signup-action"
        >
          { txt }
        </TextLink>
      )}
    </FormattedMessage>
  );

  return (
    <Box
      padding="m"
      style={{ backgroundColor: 'var(--ds-color-background-alt-100)' }}
    >
      <Text type="body" display="block">
        { intl.formatMessage(messages.loggedInMenuWelcomeMessage) }
      </Text>
      <Text type="body" display="block">
        <FormattedMessage
          defaultMessage={'{logIn} or {signUp}'}
          values={{
            logIn: logInLink,
            signUp: signUpLink,
          }}
        />
      </Text>
    </Box>
  );
};

Header.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  userInfo: PropTypes.shape({
    avatar: PropTypes.string,
    username: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  }),
  baseUrl: PropTypes.string.isRequired,
  doLogin: PropTypes.func,
  doSignup: PropTypes.func,
  redirectUrl: PropTypes.string,
};

Header.defaultProps = {
  userInfo: {},
  doLogin: null,
  doSignup: null,
};

Header.displayName = 'Header';

export default Header;
